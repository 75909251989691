import { useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Button,
  Row,
  Col,
  Grid,
  Typography,
  Alert,
  Progress,
} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { loginUser, resetPWAccount, setAccountPW } from "../apiBridge";

import logo from "../assets/new_vtkd_logo.png";
import logopattern from "../assets/VTKDBG4.png";

const zxcvbn = require("zxcvbn");

const LoginPage = () => {
  const { useBreakpoint } = Grid;
  const { Title, Paragraph, Link, Text } = Typography;
  const screens = useBreakpoint();
  const location = useLocation();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const params = new URLSearchParams(location.search);
  const from = params.get("from");
  console.log("Came from: ", from);

  const [showPage, setShowPage] = useState<
    "login" | "forgotpwowner" | "setaccountpw"
  >("login");
  const [showEmailSentAlert, setShowEmailSentAlert] = useState(false);
  const [showSuccessSetPW, setShowSuccessSetPW] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [showErrorLoginAlert, setShowErrorLoginAlert] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [newPW, setNewPW] = useState("");
  const [pendingUser, setPendingUser] = useState<{
    password: string;
    email: string;
  }>({ password: "", email: "" });

  const onFinishLogin = async (values: any) => {
    setLoadingLogin(true);
    const res = await loginUser({
      email: values.email,
      password: values.password,
    });
    const data = await res.json();
    setLoadingLogin(false);
    if (data.error) {
      setShowErrorLoginAlert(true);
    } else if (res.status === 202) {
      setShowErrorLoginAlert(false);
      setPendingUser({
        email: values.email,
        password: values.password,
      });
      setShowPage("setaccountpw");
    } else if (res.status === 200) {
      document.cookie = `token=${data.token.access_token}; max-age=${data.token.expires_in}; path=/`;
      navigate("/");
    } else {
      console.error("Unhandled Status Code: ", res.status, data);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const triggerForgotPW = () => {
    setShowPage("forgotpwowner");
  };

  const onRequestPWReset = async (values: any) => {
    setLoadingLogin(true);
    await resetPWAccount(values);
    setLoadingLogin(false);
    // Dont need to check if email is not in DB. Just show the Alert msg
    setShowEmailSentAlert(true);
  };

  const onFinishSetPW = async (values: any) => {
    setLoadingLogin(true);
    const res = await setAccountPW({
      email: pendingUser.email,
      password: pendingUser.password,
      new_password: values.retypepassword,
    });
    const data = await res.json();
    setLoadingLogin(false);
    if (data.error) {
      setShowErrorLoginAlert(true);
    } else if (res.status === 200) {
      setShowSuccessSetPW(true);
      setShowPage("login");
    } else {
      console.error("Unhandled Status Code: ", res.status, data);
    }
  };

  const resetLoginPage = () => {
    setShowPage("login");
    setShowEmailSentAlert(false);
    setShowSuccessSetPW(false);
    setShowErrorLoginAlert(false);
    setPendingUser({ password: "", email: "" });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${logopattern})`,
        backgroundColor: "#f7f7f7",
        backgroundSize: 166,
      }}
    >
      <div
        style={{
          boxShadow: "0 0 8px 0 #cbcbcb",
          borderRadius: 4,
          width: screens.md ? 472 : "calc(100% - 10px)",
          padding: 34,
          background: "white",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: 18 }}>
          <img src={logo} alt="vtkd_logo" style={{ height: 47 }} />
        </div>

        {/* LOGIN FORM */}
        {showPage === "login" && (
          <div>
            {showSuccessSetPW && (
              <Alert
                message="Password updated successfully. Please login again."
                type="success"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            {showErrorLoginAlert && (
              <Alert
                message="Incorrect username or password"
                type="error"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            <Form
              name="loginform"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onFinishLogin}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={screens.md ? "Email" : ""}
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                label={screens.md ? "Password" : ""}
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember Me</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Button
                    type="link"
                    onClick={triggerForgotPW}
                    disabled={loadingLogin || navigation.state === "loading"}
                  >
                    Forgot Password
                  </Button>
                </Col>
              </Row>

              <Form.Item style={{ margin: 0 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loadingLogin || navigation.state === "loading"}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {/* CHANGE PASSWORD OWNER */}
        {showPage === "forgotpwowner" && (
          <div>
            <Title level={2}>Forgot Password</Title>
            <Paragraph>
              We will send you an with instructions on how to reset your
              password.
            </Paragraph>
            <Form
              name="forgotpwownerform"
              layout="vertical"
              initialValues={{ remember: true }}
              onFinish={onRequestPWReset}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              validateTrigger="onSubmit"
            >
              <Form.Item
                label={screens.md ? "Email" : ""}
                name="email"
                rules={[
                  {},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                          value
                        )
                      ) {
                        return Promise.reject(
                          new Error("Please enter a valid email!")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loadingLogin}
                >
                  Request Password Reset
                </Button>
              </Form.Item>
            </Form>
            {showEmailSentAlert && (
              <Alert
                message="Email Sent"
                description={
                  <>
                    <Paragraph>
                      We have sent password reset instructions to the above
                      email. If it does not arrive soon, check your spam folder
                      or re-send the email again.
                    </Paragraph>
                    Need help?{" "}
                    <Link
                      href="https://www.virtualtkd.gg/#Contact-Us"
                      target="_blank"
                    >
                      Contact support
                    </Link>
                    .
                  </>
                }
                type="success"
                showIcon
              />
            )}
            <div style={{ textAlign: "center", marginTop: 24 }}>
              <Button type="link" onClick={resetLoginPage}>
                Back to Login
              </Button>
            </div>
          </div>
        )}

        {/* SET ACCOUNT PASSWORD */}
        {showPage === "setaccountpw" && (
          <div>
            {showErrorLoginAlert && (
              <Alert
                message="Invalid password or new password"
                type="error"
                showIcon
                style={{ marginBottom: 12 }}
              />
            )}
            <Title level={2}>Set a new password</Title>
            <Paragraph>
              Protect your account with a unique password of at least 8
              characters long.
            </Paragraph>
            <Form
              name="setpwmemberform"
              layout="vertical"
              initialValues={{ accepttnc: true }}
              onFinish={onFinishSetPW}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label={screens.md ? "New Password" : ""}
                name="password"
                rules={[
                  {},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const score = zxcvbn(value).score;
                      setNewPW(value);
                      setPasswordScore(score);
                      if (
                        !value ||
                        value.length < 8 ||
                        !/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(value)
                      ) {
                        return Promise.reject();
                      } else if (score < 2) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <div>
                  <Input.Password placeholder="New Password" />
                  <Progress
                    percent={Math.max(passwordScore * 25, 1)}
                    showInfo={false}
                    strokeColor={
                      passwordScore * 25 < 50
                        ? "#ff4d4f"
                        : passwordScore * 25 < 75
                          ? "#ff9e07"
                          : "#00b7b2"
                    }
                  />
                  <div style={{ marginBottom: 12 }}>
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      Strength:{" "}
                      <Text type="secondary" style={{ fontSize: "inherit" }}>
                        {passwordScore === 0
                          ? "VERY WEAK"
                          : passwordScore === 1
                            ? "VERY WEAK"
                            : passwordScore === 2
                              ? "WEAK"
                              : passwordScore === 3
                                ? "FAIR"
                                : "STRONG"}
                      </Text>
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      Your password must include:
                    </Text>
                  </div>
                  <div>
                    {/^(?=.*[a-zA-Z]).+$/.test(newPW) ? (
                      <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                    )}
                    &nbsp;&nbsp;
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      At least 1 letter
                    </Text>
                  </div>
                  <div>
                    {/^(?=.*[0-9]).+$/.test(newPW) ? (
                      <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                    )}
                    &nbsp;&nbsp;
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      At least 1 number
                    </Text>
                  </div>
                  <div>
                    {newPW.length >= 8 ? (
                      <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                    )}
                    &nbsp;&nbsp;
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      Minimum of 8 characters
                    </Text>
                  </div>
                  <div>
                    {passwordScore > 1 ? (
                      <CheckCircleOutlined style={{ color: "#00b7b2" }} />
                    ) : (
                      <CloseCircleOutlined style={{ color: "#ff4d4f" }} />
                    )}
                    &nbsp;&nbsp;
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      Minimum WEAK password strength
                    </Text>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                label={screens.md ? "Retype password" : ""}
                name="retypepassword"
                dependencies={["password"]}
                rules={[
                  {},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && getFieldValue("password")) {
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      } else if (!value) {
                        return Promise.resolve();
                      } else if (getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The new password that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Retype password" />
              </Form.Item>
              <Form.Item
                name="accepttnc"
                valuePropName="checked"
                rules={[
                  { required: true, message: "" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) return Promise.resolve();
                      return Promise.reject(
                        new Error("Please check the checkbox to continue.")
                      );
                    },
                  }),
                ]}
              >
                <Checkbox>
                  By creating an account, you agree to our{" "}
                  <Link
                    href="https://www.virtualtkd.gg/terms-of-service"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>{" "}
                  &amp;{" "}
                  <Link
                    href="https://www.virtualtkd.gg/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Checkbox>
              </Form.Item>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loadingLogin}
                  disabled={
                    !/^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(newPW) ||
                    newPW.length < 8 ||
                    passwordScore < 2
                  }
                >
                  Set Password
                </Button>
              </Form.Item>
            </Form>

            <div style={{ textAlign: "center", marginTop: 12 }}>
              <Button type="link" onClick={resetLoginPage}>
                Back to Login
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
