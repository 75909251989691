import { useState } from "react";
import dayjs from 'dayjs';
import {
  Modal,
  Typography,
  Form,
  Button,
  Grid,
  Space,
  Alert,
  DatePicker,
  DatePickerProps
} from "antd";
import { useRevalidator } from "react-router-dom";
import { createManualSubscription } from "../../apiBridge";
import { MessageInstance } from "antd/es/message/interface";

interface Props {
  isOpen: boolean;
  isAdmin: boolean;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  notifController: MessageInstance;
  organizationId: string;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const ManualModal: React.FC<Props> = ({
  isAdmin,
  isOpen,
  controller,
  notifController,
  organizationId
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const revalidate = useRevalidator();

  const [loadingState, setLoadingState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [dateDelta, setDateDelta] = useState(-1);

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    const today = new Date();
    const diff = date?.diff(today, 'days');
    setDateDelta(diff);
  };

  const onFinishManual = async () => {
    setLoadingState(true);
    console.log(dateDelta);
    if (dateDelta < 0) {
      notifController && notifController.open({
        type: "error",
        content: "Please select a date in the future.",
      });
      setDateDelta(-1);
      setLoadingState(false);
      return;
    }
    const payload = {
      organization_id: organizationId,
      end_at_days: dateDelta + 1,
    };
    const res = await createManualSubscription(payload);
    if (res.status === 200) {
      notifController.open({
        type: "success",
        content: "New account created!",
      });
      controller(false);
      revalidate.revalidate();
    } else {
      const data = await res.json();
      setErrorMsg(data.error || "Unknown Error. Please try again next time.");
    }
    setDateDelta(-1);
    setLoadingState(false);
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen}
      // width={380}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
          setErrorMsg("");
        }
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Create Manual Subscription</Title>
        <Text type="secondary">
          Please input the date that the subscription will expire.
        </Text>
      </div>
      {errorMsg !== "" && (
        <Alert
          type="error"
          message={errorMsg}
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        name="createManualForm"
        // layout="vertical"
        onFinish={onFinishManual}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        validateTrigger="onSubmit"
        requiredMark={false}
        labelCol={{ flex: "100px" }}
        wrapperCol={{ flex: "auto" }}
      >
        <Form.Item
          label={screens.md ? "Date" : ""}
          name="date"
        >
          <DatePicker onChange={onChange} minDate={dayjs().add(1, 'd')} allowClear showNow={false} />
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="submit" loading={loadingState}>
                Create Manual Subscription
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default ManualModal;
