import { useEffect, useState } from "react";
import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import { IAdminUser, IAuthUser } from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Button,
  FloatButton,
  Grid,
  message,
  Avatar,
  Tag,
  Space
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import CreateAdminModal from "./createAdminModal";
import EditAdminModal from "./editAdminModal";
import DeleteAdminModal from "./deleteAdminModal";
import { getAdminUsers } from "../../apiBridge";

const { Title } = Typography;
const { Search } = Input;
const { useBreakpoint } = Grid;

const AllDistributors: React.FC = () => {
  const data = useLoaderData() as IAdminUser[];
  const screens = useBreakpoint();
  const { authUser } = useOutletContext() as { authUser: IAuthUser };

  const [usersData, setUsersData] = useState<IAdminUser[]>(data);
  const [loadingState, setLoadingState] = useState(false);
  const [triggerTableReload, setTriggerTableReload] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<IAdminUser[]>(data);

  const [selectedUser, setSelectedUser] = useState<IAdminUser>()

  const [createAccModalOpen, setCreateAccModalOpen] = useState(false);
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  const editAccount = (record: IAdminUser) => {
    setSelectedUser(record);
    setIsEditAccountModalOpen(true)
  }

  useEffect(() => {
    if (triggerTableReload) {
      console.info("trigger reload")
      const getAdmins = async () => {
        setLoadingState(true)
        const res = await getAdminUsers();
        setLoadingState(false);
        setTriggerTableReload(false);
        if (res.status === 200) {
          const data = await res.json();
          
          setUsersData(data.users);
        }
      };
      getAdmins();
    }
  }, [triggerTableReload]);

  useEffect(() => {
    if (usersData.length > 0) {
      if (searchValue) {
        const temp = usersData.filter((distri) =>
          distri.name.includes(searchValue)
        );
        setFilteredData(temp);
      } else {
        setFilteredData(usersData);
      }
    }
  }, [usersData, searchValue]);

  const columns: TableColumnsType<IAdminUser> = [
    {
        title: "Name",
        key: "name",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text, row) => (
          <div>
            <Avatar src={row.picture} />
            &nbsp; &nbsp;
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchValue]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
            {text === authUser.account_info.name && (
              <Tag color="blue" style={{ marginLeft: 10 }}>
                Me
              </Tag>
            )}
          </div>
        ),
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => a.email.localeCompare(b.email),
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ),
      },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (text, record: IAdminUser) => (
        record.name !== authUser.account_info.name ? (
          <Space>
            <Button type="primary" onClick={() => editAccount(record)}>Edit</Button>
          </Space>
        ) : <></>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <CreateAdminModal
        controller={setCreateAccModalOpen}
        isOpen={createAccModalOpen}
        notifController={messageApi}
        reloadController={setTriggerTableReload}
      />

      <EditAdminModal
        controller={setIsEditAccountModalOpen}
        isOpen={isEditAccountModalOpen}
        currentAccount={selectedUser}
        deleteController={setIsDeleteAccountModalOpen}
        reloadController={setTriggerTableReload}
        notifController={messageApi}
      />

    <DeleteAdminModal
        controller={setIsDeleteAccountModalOpen}
        isOpen={isDeleteAccountModalOpen}
        user={selectedUser}
        reloadController={setTriggerTableReload}
        notifController={messageApi}
      />

      <Row>
        {!screens.md && (
          <FloatButton
            icon={<PlusOutlined />}
            onClick={() => setCreateAccModalOpen(true)}
          />
        )}
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col md={8} sm={8} xs={24}>
                <Title level={4} style={{ marginBottom: 0 }}>
                  Admin Users
                </Title>
              </Col>
              <Col md={16} sm={16} xs={24}>
                <div style={{ display: "flex" }}>
                  <Search
                    placeholder="Search .."
                    allowClear
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {screens.md && (
                    <Button
                      type="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => setCreateAccModalOpen(true)}
                    >
                      Create Administrator
                    </Button>
                  )}
                </div>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  loading={loadingState}
                  rowKey="user_id"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AllDistributors;
