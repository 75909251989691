import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
  RouterProvider,
} from "react-router-dom";

// Import Pages
import LoginPage from "./loginpage";
import RootLayout from "./rootlayout";
import AllDistributors from "./landing/allDistributors";
import SingleDistributor from "./landing/singleDistributor";
import AllAdmins from './landing/allAdmins';
import Owners from "./landing/allOwners";
import SingleOwner from "./landing/singleOwner";
import Organizations from "./landing/allOrganizations";

import {
  getAdminUsers,
  getAllDistributors,
  getAllOrganizations,
  getAllOwners,
  getAuthUser,
  getDistributorDetail,
  getOwnerDetail,
} from "./apiBridge";

import "antd/dist/reset.css";
import "./App.css";
import {
  IAdminUser,
  IAuthUser,
  IDistributionDetail,
  IDistributionSummary,
  IOrganization,
  IOwnerDetail,
  IOwnerSummary,
} from "./interface";

import { ConfigProvider } from "antd";
import "@fontsource/poppins";

async function getUserLoader({ request }: LoaderFunctionArgs) {
  const res = await getAuthUser();
  console.log(request);
  if (res.status === 200) {
    const data = (await res.json()) as IAuthUser;
    return data;
  }
  return redirect("/login");
}

async function redirectLoader({ request }: LoaderFunctionArgs) {
  console.log("check auth");
  const res = await getAuthUser();
  if (res.status === 200) {
    const { account_role } = (await res.json()) as IAuthUser;
    return redirect(account_role === "Administrator" ? "/distributors" : "/organizations");
  }
  return null;
}

async function getAllDistributorsLoader() {
  console.log("Getting all disributors");
  const res = await getAllDistributors();
  if (res.status === 200) {
    const { distributions } = (await res.json()) as {
      distributions: IDistributionSummary[];
    };
    return distributions;
  } else if (res.status === 401) {
    return redirect("/login");
  }
}

async function getAdminUsersLoader() {
  const res = await getAdminUsers();
  if (res.status === 200) {
    const { users } = (await res.json()) as {
      users: IAdminUser[];
    };
    return users;
  } else if (res.status === 401) {
    return redirect("/login");
  }
}

async function getSingleDistributorLoader({ params }: LoaderFunctionArgs) {
  const { distribution_name } = params;
  if (distribution_name) {
    console.log("Getting distributor " + distribution_name);
    const res = await getDistributorDetail({ distribution_name });
    if (res.status === 200) {
      const data = (await res.json()) as IDistributionDetail;
      return data;
    } else if (res.status === 401) {
      return redirect("/login");
    }
  } else {
    console.log("Getting distributor from token");
    const res = await getDistributorDetail();
    if (res.status === 200) {
      const data = (await res.json()) as IDistributionDetail;
      return data;
    } else if (res.status === 401) {
      return redirect("/login");
    } else if (res.status === 303) {
      return redirect("/distributors");
    }
  }
}

async function getOwnerLoader() {
  console.log("Getting all owners");
  const res = await getAllOwners();
  if (res.status === 200) {
    const { owners } = (await res.json()) as {
      owners: IOwnerSummary[];
    };
    return owners;
  } else if (res.status === 401) {
    return redirect("/login");
  }
  return null
}

// Get Organizations using the owner_id
async function getSingleOwnerLoader({ params }: LoaderFunctionArgs) {
  const { owner_id } = params;
  if (owner_id) {
    console.log("Getting owners " + owner_id);
    const res = await getOwnerDetail({ owner_id });
    if (res.status === 200) {
      const data = (await res.json()) as IOwnerDetail;
      return data;
    } else if (res.status === 401) {
      return redirect("/login");
    }
  } else {
    console.log("Getting owners from token");
    const res = await getOwnerDetail();
    if (res.status === 200) {
      const data = (await res.json()) as IOwnerDetail;
      return data;
    } else if (res.status === 401) {
      return redirect("/login");
    } else if (res.status === 303) {
      return redirect("/owners");
    }
  }
}

async function getAllOrganizationsLoader() {
  console.log("Getting all organizations");
  const res = await getAllOrganizations();
  if (res.status === 200) {
    const { organizations } = (await res.json()) as {
      organizations: IOrganization[];
    };
    return organizations;
  } else if (res.status === 401) {
    return redirect("/login");
  }
  return null;
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
    loader: redirectLoader,
  },
  {
    element: <RootLayout />,
    loader: getUserLoader,
    children: [
      {
        path: "/",
        element: <SingleDistributor />,
        loader: getSingleDistributorLoader,
      },
      {
        path: "/admins",
        element:<AllAdmins />,
        loader: getAdminUsersLoader,
      },
      {
        path: "/distributors",
        element: <AllDistributors />,
        loader: getAllDistributorsLoader,
      },
      {
        path: "/distributors/:distribution_name",
        element: <SingleDistributor />,
        loader: getSingleDistributorLoader,
      },
      {
        path: "/owners",
        element: <Owners />,
        loader: getOwnerLoader,
      },
      {
        path: "/owners/:owner_id",
        element: <SingleOwner />,
        loader: getSingleOwnerLoader,
      },
      {
        path: "/organizations",
        element: <Organizations />,
        loader: getAllOrganizationsLoader,
      },
    ],
  },
  {
    path: "*",
    loader: () => redirect("/"),
  },
]);

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#40a9ff",
          fontFamily: "Poppins",

          // Alias Token
        },
      }}
    >
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  );
}

export default App;
