const isProduction = process.env.NODE_ENV === "production";
const accountAPI = isProduction
  ? "/api/account"
  : process.env.REACT_APP_ACCOUNT_API_URL;

interface loginObj {
  email: string;
  password: string;
}

interface setPWObj {
  email: string;
  password: string;
  new_password: string;
}

interface resetOwnerObj {
  email: string;
}

interface distributionObj {
  distribution_name: string;
}

interface updateUserObj {
  account_id: string;
  block: boolean;
}

interface deleteUserObj {
  account_id: string
  name: string
}

interface updateUserInfoObj {
  account_id: string
  name: string
}

export enum roleName {
  CustomerService = "CustomerService",
  Distributor = "Distributor",
  Reseller = "Reseller",
  Administrator = "Administrator"
}

interface createAccObj {
  email: string;
  role: roleName;
  distribution?: {
    join_distribution?: {
      distribution_name: string;
    };
    create_distribution?: {
      distribution_name: string;
    };
  };
}

interface createOrgObj {
  organization_name: string;
  email: string;
}

export async function getAuthUser() {
  try {
    const response = await fetch(`${accountAPI}/info`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

// Auth API

export async function loginUser(credentials: loginObj) {
  try {
    const response = await fetch(`${accountAPI}/login-with-credentials`, {
      method: "POST",
      body: JSON.stringify(credentials),
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function setAccountPW(credentials: setPWObj) {
  try {
    const response = await fetch(`${accountAPI}/change-password`, {
      method: "POST",
      body: JSON.stringify(credentials),
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function resetPWAccount(credentials: resetOwnerObj) {
  try {
    const response = await fetch(`${accountAPI}/reset-password`, {
      method: "POST",
      body: JSON.stringify(credentials),
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}


export async function createAdmin(email: string) {
  try {
    const response = await fetch(`${accountAPI}/create-admin`, {
      method: "POST",
      body: JSON.stringify({email}),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function createNewAccount(credentials: createAccObj) {
  try {
    const response = await fetch(`${accountAPI}/create`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}


export async function updateUserInfo(credentials: updateUserInfoObj) {
  try {
    const response = await fetch(`${accountAPI}/update-info`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}


export async function deleteUser(credentials: deleteUserObj) {
  try {
    const response = await fetch(`${accountAPI}/delete`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}


export async function updateUser(credentials: updateUserObj) {
  try {
    const response = await fetch(`${accountAPI}/update-access`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

// Distributor API

export async function getAllDistributors() {
  try {
    const response = await fetch(`${accountAPI}/distributions`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function getAdminUsers() {
  try {
    const response = await fetch(`${accountAPI}/admins`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function getDistributorDetail(credentials?: distributionObj) {
  try {
    const response = await fetch(`${accountAPI}/distribution`, {
      method: "POST",
      // ...(credentials ? { body: JSON.stringify(credentials) } : {}),
      body: JSON.stringify(credentials || {}),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

// Owner API

export async function getAllOwners() {
  try {
    const response = await fetch(`${accountAPI}/owners`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function getOwnerDetail(credentials?: { owner_id: string }) {
  try {
    const response = await fetch(`${accountAPI}/owners/${credentials?.owner_id}/organizations`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;
  } catch (error: any) {
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

// Organization API

export async function getAllOrganizations() {
  try {
    const response = await fetch(`${accountAPI}/organizations`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;

    // Work with the parsed JSON data
  } catch (error: any) {
    // Handle any errors that occurred during the fetch
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function createOrganization(credentials: createOrgObj) {
  try {
    const response = await fetch(`${accountAPI}/create-owner-and-organization`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;
  } catch (error: any) {
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

// Subscription API

export async function createManualSubscription(credentials: { organization_id: string, end_at_days: number }) {
  try {
    const response = await fetch(`${accountAPI}/create-demo-subscription`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;
  } catch (error: any) {
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function createFirstInvoice(credentials?: { organization_id: string }) {
  try {
    const response = await fetch(`${accountAPI}/send-first-payment-link`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;
  } catch (error: any) {
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }
}

export async function disableSubscription(credentials?: { subscription_id: string }) {
  try {
    const response = await fetch(`${accountAPI}/disable-subscription`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: {
        Authorization: "Bearer " + getCookie("token"),
      },
    });

    // Parse the response JSON
    return response;
  } catch (error: any) {
    console.error("Fetch error:", typeof error, error);
    return new Response(JSON.stringify({ error: error.message }), {
      status: 500,
      headers: { "Content-Type": "application/json" },
    });
  }

}

// Cookie functions

export function getCookie(name: string) {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim(); // Remove leading/trailing spaces

    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1); // Add 1 to skip the '=' character
    }
  }
  return "";
}

export function deleteCookie(name: string) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
