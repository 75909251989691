import { useEffect, useState } from "react";
import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import { IAuthUser, IDistributionSummary } from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Button,
  FloatButton,
  Grid,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import AccountModal from "./accountModal";

const { Title } = Typography;
const { Search } = Input;
const { useBreakpoint } = Grid;

const AllDistributors: React.FC = () => {
  const data = useLoaderData() as IDistributionSummary[];
  const screens = useBreakpoint();
  const { authUser } = useOutletContext() as { authUser: IAuthUser };
  console.log(data);

  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] =
    useState<IDistributionSummary[]>(data);
  const [createAccModalOpen, setCreateAccModalOpen] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      if (searchValue) {
        const temp = data.filter((distri) =>
          distri.display_name.includes(searchValue)
        );
        setFilteredData(temp);
      } else {
        setFilteredData(data);
      }
    }
  }, [data, searchValue]);

  const columns: TableColumnsType<IDistributionSummary> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "display_name",
      sorter: (a, b) => a.display_name.localeCompare(b.display_name),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_, record) => <Link to={record.name}>Manage</Link>,
    },
  ];

  return (
    <>
      {contextHolder}
      <AccountModal
        isAdmin={authUser.account_role === "Administrator"}
        controller={setCreateAccModalOpen}
        isOpen={createAccModalOpen}
        distributionList={data}
        notifController={messageApi}
      />
      <Row>
        {!screens.md && (
          <FloatButton
            icon={<PlusOutlined />}
            onClick={() => setCreateAccModalOpen(true)}
          />
        )}
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col md={8} sm={8} xs={24}>
                <Title level={4} style={{ marginBottom: 0 }}>
                  All Distributors
                </Title>
              </Col>
              <Col md={16} sm={16} xs={24}>
                <div style={{ display: "flex" }}>
                  <Search
                    placeholder="Search Distributor"
                    allowClear
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {screens.md && (
                    <Button
                      type="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => setCreateAccModalOpen(true)}
                    >
                      Create Account
                    </Button>
                  )}
                </div>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  rowKey="id"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AllDistributors;
