import { useEffect, useState } from "react";
import {
  useLoaderData,
  useOutletContext,
} from "react-router-dom";
import {
  IAuthUser,
  IOrganization,
  IOwnerDetail,
} from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Space,
  Tag,
} from "antd";
import Highlighter from "react-highlight-words";

const { Title } = Typography;
const { Search } = Input;
const SingleOwner: React.FC = () => {
  const data = useLoaderData() as IOwnerDetail;
  const { authUser } = useOutletContext() as { authUser: IAuthUser };
  console.log(data);

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<IOrganization[]>(
    data.organizations
  );

  useEffect(() => {
    if (data.organizations?.length > 0) {
      if (searchValue) {
        const temp = data.organizations.filter(
          (organization) =>
            organization.organization_name.includes(searchValue)
        );
        setFilteredData(temp);
      } else {
        setFilteredData(data.organizations);
      }
    }
  }, [data, searchValue]);

  const columns: TableColumnsType<IOrganization> = [
    {
      title: "Name",
      key: "organization_name",
      dataIndex: "organization_name",
      sorter: (a, b) => a.organization_name.localeCompare(b.organization_name),
      render: (text, row) => (
        <div>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
          {text === authUser.account_info.name && (
            <Tag color="blue" style={{ marginLeft: 10 }}>
              Me
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Plan Name",
      key: "plan_name",
      dataIndex: "plan_name",
      sorter: (a, b) => a.plan_name.localeCompare(b.plan_name),
      render: (text) => (
        <div>{text}</div>
      ),
    },
    {
      title: "Auto Renew",
      key: "auto_renew",
      dataIndex: "auto_renew",
      render: (auto_renew) => (
        <div>
          {auto_renew ? (
            <Tag color="green">Yes</Tag>
          ) : (
            <Tag color="red">No</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Created At",
      key: "organization_created_at",
      dataIndex: "organization_created_at",
      sorter: (a, b) => a.organization_created_at.localeCompare(b.organization_created_at),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "",
      key: "action",
      dataIndex: "organization_id",
      width: 200,
      render: (text, record) => (
        <Space>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col md={8} sm={8} xs={24}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.owner_email}
                </Title>
              </Col>
              <Col md={16} sm={16} xs={24}>
                <div style={{ display: "flex" }}>
                  <Search
                    placeholder="Search Organizations"
                    allowClear
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  rowKey="user_id"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SingleOwner;
