import { useEffect, useState } from "react";
import {
  useLoaderData,
  useRevalidator,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  IAuthUser,
  IDistributionDetail,
  IDistributionUser,
} from "../../interface";
import {
  Col,
  Row,
  Table,
  type TableColumnsType,
  Typography,
  Input,
  Avatar,
  Button,
  Space,
  FloatButton,
  Grid,
  message,
  Tag,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { updateUser, getDistributorDetail, deleteUser } from "../../apiBridge";
import CreateAccountModal from "./createAccountModal";
import EditAccountModal from "./editAccountModal";
import DeleteAccountModal from "./deleteAccountModal";

const { Title } = Typography;
const { Search } = Input;
const { useBreakpoint } = Grid;

interface IDistributionUserColumn extends IDistributionUser{
  children?: IDistributionUser[];
}

const SingleDistributor: React.FC = () => {
  const data = useLoaderData() as IDistributionDetail;
  const screens = useBreakpoint();
  //const revalidate = useRevalidator();
  const { authUser } = useOutletContext() as { authUser: IAuthUser };
  const { distribution_name } = useParams();

  const [loadingState, setLoadingState] = useState(false);
  const [usersData, setUsersData] = useState<IDistributionDetail>(data);
  const [triggerTableReload, setTriggerTableReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IDistributionUser>()

  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState<IDistributionUser[]>(
    data.distribution_members
  );
  const [createAccModalOpen, setCreateAccModalOpen] = useState(false);
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  useEffect(() => {
    if (triggerTableReload && distribution_name) {
      setLoadingState(true)
      console.info("trigger reload", distribution_name)
      const getUsersFromDistribution = async () => {
        const res = await getDistributorDetail({ distribution_name });
        console.info(res)
        setLoadingState(false);
        setTriggerTableReload(false);
        if (res.status === 200) {
          const data = await res.json();
          console.info("hhello", data);
          
          setUsersData(data);
        }
      };
      getUsersFromDistribution();
    }
  }, [triggerTableReload, distribution_name]);

  useEffect(() => {
    let memberTree :Map<string, IDistributionUser> = new Map();
    let parentTree :{[key: string]: string[]} = {};

    for (let i = 0; i < usersData.distribution_members.length; i++) {
      let member = usersData.distribution_members[i];
      if (searchValue) {
        if (!member.email.includes(searchValue) && !member.name.includes(searchValue)) {
          continue;
        } 
      }

      memberTree.set( member.user_id, member );
      if (member.metadata && member.metadata.created_by) {
        if (!parentTree[member.metadata.created_by]) {
          parentTree[member.metadata.created_by] = [];
        }
        
        parentTree[member.metadata.created_by].push( member.user_id );
      }
    }

    for (let parentId in parentTree) {
      if (memberTree.has(parentId)) {
        let parentMember = memberTree.get(parentId) as IDistributionUserColumn;
        let children :IDistributionUser[] = [];

        if (parentMember.roles && parentMember.roles.length > 0 && parentMember.roles[0].name !== "Reseller") {
          continue;
        }
        
        for (let i = 0; i < parentTree[parentId].length; i++) {
          if (memberTree.has( parentTree[parentId][i] )) {
            children.push( memberTree.get( parentTree[parentId][i] ) as IDistributionUser )
            memberTree.delete( parentTree[parentId][i] );
          }
        }
        
        if (children.length > 0) {
          parentMember.children = children;
          memberTree.set(parentId, parentMember);
        }
      } 
    }

    let users :IDistributionUserColumn[] = [];
    for (let i = 0; i < usersData.distribution_members.length; i++) {
        let member = usersData.distribution_members[i];

        if (memberTree.has( member.user_id )) {
            users.push( memberTree.get( member.user_id ) as IDistributionUserColumn );
        }
    }

    setFilteredData(users);
  }, [usersData, searchValue]);

  const editAccount = (record: IDistributionUser) => {
    setSelectedUser(record);
    setIsEditAccountModalOpen(true)
  }

  const columns: TableColumnsType<IDistributionUser> = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, row) => (
        <div>
          <Avatar src={row.picture} />
          &nbsp; &nbsp;
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchValue]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
          {text === authUser.account_info.name && (
            <Tag color="blue" style={{ marginLeft: 10 }}>
              Me
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchValue]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Role",
      key: "roles",
      dataIndex: "roles",
      render: (roles) => (
        <div>
          {roles.map((role: { id: string, name: string }) => (
            <Tag key={role.id} color="blue">
              {role.name === "CustomerService" ? "AccountManager" : role.name}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      dataIndex: "user_id",
      width: 200,
      render: (text, record: IDistributionUser) => (
        record.name !== authUser.account_info.name ? (
          <Space>

            {(authUser.account_role === "Administrator" || (record.roles && record.roles.length > 0 && record.roles[0].name !== "Distributor" && record.roles[0].name !== "Administrator")) ? (
              <Button type="primary" onClick={() => editAccount(record)}>Edit</Button>
            ) : ""}

            {record.blocked ? (
              <Button type="primary" ghost onClick={() => updateUserStatus(text, false)}>
                Unblock
              </Button>
            ) : (
              <Button type="primary" danger onClick={() => updateUserStatus(text, true)}>
                Block
              </Button>
            )}

          </Space>
        ) : <></>
      ),
    },
  ];



  const updateUserStatus = async (account_id: string, block: boolean) => {
    setLoadingState(true)
    const res = await updateUser({ account_id, block });
    if (res.status === 200) {
      messageApi.success('update success');
      //revalidate.revalidate();

      setTimeout(() => {
        setTriggerTableReload(true)
      }, 1500); // auth0 update block status not so fast.... 
    } else {
      messageApi.error("update error");
      setLoadingState(false);
    }
  };

  return (
    <>
      {contextHolder}
      <CreateAccountModal
        isAdmin={authUser.account_role === "Administrator"}
        isReseller={authUser.account_role === "Reseller"}
        controller={setCreateAccModalOpen}
        isOpen={createAccModalOpen}
        currentDistribution={data}
        reloadController={setTriggerTableReload}
        notifController={messageApi}
      />

      <EditAccountModal
        isAdmin={authUser.account_role === "Administrator"}
        controller={setIsEditAccountModalOpen}
        isOpen={isEditAccountModalOpen}
        currentDistribution={data}
        currentAccount={selectedUser}
        reloadController={setTriggerTableReload}
        deleteController={setIsDeleteAccountModalOpen}
        notifController={messageApi}
      />

    <DeleteAccountModal
        controller={setIsDeleteAccountModalOpen}
        isOpen={isDeleteAccountModalOpen}
        user={selectedUser}
        reloadController={setTriggerTableReload}
        notifController={messageApi}
      />
      
      <Row>
        {!screens.md && (
          <FloatButton
            icon={<PlusOutlined />}
            onClick={() => setCreateAccModalOpen(true)}
          />
        )}
        <Col span={24}>
          <div
            style={{
              background: "white",
              borderRadius: 4,
              boxShadow: "0 0 8px 0 #cbcbcb",
              padding: 20,
            }}
          >
            <Row gutter={[24, 24]}>
              <Col md={8} sm={8} xs={24}>
                <Title
                  level={4}
                  style={{
                    marginBottom: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data.distribution_name}
                </Title>
              </Col>
              <Col md={16} sm={16} xs={24}>
                <div style={{ display: "flex" }}>
                  <Search
                    placeholder="Search Member"
                    allowClear
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {screens.md && (
                    <Button
                      type="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => setCreateAccModalOpen(true)}
                    >
                      Create Account
                    </Button>
                  )}
                </div>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  loading={loadingState}
                  dataSource={filteredData}
                  rowKey="user_id"
                  scroll={{
                    x: "max-content",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SingleDistributor;
