import { useState } from "react";
import {
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Grid,
  Space,
  Alert,
} from "antd";
import { useRevalidator } from "react-router-dom";
import { createOrganization } from "../../apiBridge";
import { MessageInstance } from "antd/es/message/interface";

interface Props {
  isOpen: boolean;
  isAdmin: boolean;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  notifController: MessageInstance;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const OrganizationModal: React.FC<Props> = ({
  isAdmin,
  isOpen,
  controller,
  notifController,
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const revalidate = useRevalidator();

  const [loadingState, setLoadingState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onFinishCreateOrg = async (values: any) => {
    setLoadingState(true);
    const { name, email } = values;
    const payload = {
      organization_name: name,
      email: email,
    };
    console.log(payload);
    const res = await createOrganization(payload);
    if (res.status === 200) {
      notifController.open({
        type: "success",
        content: "New organization created!",
      });
      controller(false);
      revalidate.revalidate();
    } else {
      const data = await res.json();
      setErrorMsg(data.error || "Unknown Error. Please try again next time.");
    }
    setLoadingState(false);
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen}
      // width={380}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
          setErrorMsg("");
        }
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Create Organization</Title>
        <Text type="secondary">
          This will create also a new owner if it does not exist.
        </Text>
      </div>
      {errorMsg !== "" && (
        <Alert
          type="error"
          message={errorMsg}
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        name="createorganizationForm"
        // layout="vertical"
        onFinish={onFinishCreateOrg}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          name: "",
        }}
        form={form}
        validateTrigger="onSubmit"
        requiredMark={false}
        labelCol={{ flex: "100px" }}
        wrapperCol={{ flex: "auto" }}
      >
        {/* <Title level={5}>User Details</Title> */}
        <Form.Item
          label={screens.md ? "Name" : ""}
          name="name"
        >
          <Input placeholder="Acme Organization" />
        </Form.Item>

        <Form.Item
          label={screens.md ? "Email" : ""}
          name="email"
        >
          <Input placeholder="hello@acme.co" />
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="submit" loading={loadingState}>
                Create Organization
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default OrganizationModal;
