import { useState } from "react";
import {
  Modal,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Grid,
  Space,
  Radio,
  Divider,
  Alert,
} from "antd";
import { useRevalidator } from "react-router-dom";
import { IDistributionSummary } from "../../interface";
import { createNewAccount } from "../../apiBridge";
import { MessageInstance } from "antd/es/message/interface";

interface Props {
  isOpen: boolean;
  isAdmin: boolean;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  distributionList: IDistributionSummary[];
  notifController: MessageInstance;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const AccountModal: React.FC<Props> = ({
  isAdmin,
  isOpen,
  controller,
  distributionList,
  notifController,
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const revalidate = useRevalidator();

  const [loadingState, setLoadingState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [distributionMode, setDistributionMode] = useState<
    "create_distribution" | "join_distribution"
  >("create_distribution");

  const onFinishCreateAcc = async (values: any) => {
    setLoadingState(true);
    const { choose, distribution_name, email, role } = values;
    const payload = {
      email,
      role,
      distribution: {
        [choose]: {
          distribution_name,
        },
      },
    };
    console.log(payload);
    const res = await createNewAccount(payload);
    if (res.status === 200) {
      notifController.open({
        type: "success",
        content: "New account created!",
      });
      controller(false);
      revalidate.revalidate();
    } else {
      const data = await res.json();
      setErrorMsg(data.error || "Unknown Error. Please try again next time.");
    }
    setLoadingState(false);
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen}
      // width={380}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
          setDistributionMode("create_distribution");
          setErrorMsg("");
        }
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Create Account</Title>
        <Text type="secondary">
          Create account for a new or existing distribution
        </Text>
      </div>
      {errorMsg !== "" && (
        <Alert
          type="error"
          message={errorMsg}
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        name="createAccountForm"
        // layout="vertical"
        onFinish={onFinishCreateAcc}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          role: "CustomerService",
          choose: "create_distribution",
        }}
        form={form}
        validateTrigger="onSubmit"
        requiredMark={false}
        labelCol={{ flex: "100px" }}
        wrapperCol={{ flex: "auto" }}
      >
        <Title level={5}>User Details</Title>
        <Form.Item
          label={screens.md ? "Email" : ""}
          name="email"
          rules={[
            {},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                  )
                ) {
                  return Promise.reject(
                    new Error("Please enter a valid email!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label={screens.md ? "Role" : ""}
          name="role"
          rules={[{ required: true, message: "Please select a role!" }]}
        >
          <Select disabled={!isAdmin}>
            <Select.Option key="CustomerService" value="CustomerService">
              Account Manager
            </Select.Option>
            <Select.Option key="Distributor" value="Distributor">
              Distributor
            </Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Title level={5}>Distribution</Title>
        <Form.Item
          label={screens.md ? "Choose One" : ""}
          name="choose"
          rules={[{ required: true, message: "Please select an option!" }]}
        >
          <Radio.Group
            onChange={(e) => {
              setDistributionMode(e.target.value);
              form.setFieldValue("distribution_name", undefined);
            }}
          >
            <Radio value="create_distribution">Create New</Radio>
            <Radio
              value="join_distribution"
              disabled={!distributionList.length}
            >
              Choose Existing
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={screens.md ? "Name" : ""}
          name="distribution_name"
          rules={[
            {},
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !/^[a-zA-Z0-9\-._ ]/.test(value)) {
                  return Promise.reject(
                    new Error("Only letters, numbers, hyphens, underscores")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          normalize={(value) => value.toLowerCase().replace(/\s/g, '_')}
        >
          {distributionMode === "join_distribution" ? (
            <Select disabled={!isAdmin} placeholder="Select a distribution">
              {distributionList.map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.display_name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input placeholder="New Distribution" />
          )}
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="submit" loading={loadingState}>
                Create Account
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default AccountModal;
