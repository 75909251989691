import { useState } from "react";
import { Modal, Typography, Form, Input, Button, Space, message } from "antd";
import { deleteUser } from "../../apiBridge";
import { IDistributionUser } from "../../interface";
import { MessageInstance } from "antd/es/message/interface";

interface Props {
  isOpen: boolean;
  user?: IDistributionUser;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
  
  notifController: MessageInstance;
}

const { Title, Text } = Typography;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const DeleteUserModal: React.FC<Props> = ({
  isOpen,
  user,
  controller,
  reloadController,
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage()

  const [loadingState, setLoadingState] = useState(false);

  const onFinishDeleteUser = async (values: any) => {
    if (user) {
      setLoadingState(true);
      const res = await deleteUser({ account_id: user.user_id, name: user.name });
      setLoadingState(false);
      if (res.status === 200) {
        messageApi.success('Account deleted successfully')
        reloadController(true);
        controller(false);
      }
    }
  };

  return (
    <>
    {contextHolder}
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen && user !== undefined}
      width={380}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
        }
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Delete account</Title>
        <Text type="danger">
          Warnning: This action will result in the permanent deletion of the account from the system, and it cannot be recovered.
        </Text>
      </div>
      <Form
        name="createuserform"
        layout="vertical"
        onFinish={onFinishDeleteUser}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        validateTrigger="onSubmit"
      >
        <Form.Item
          label="Type 'this account name' to confirm remove"
          name="confirmation"
          rules={[
            { message: "Please type 'this account name' to remove user." },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!user) {
                    return Promise.reject( new Error('name is empty') );
                }

                if (value !== user.name) {
                  return Promise.reject(
                    new Error("Please type the account name")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>
        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingState}
                danger
              >
                Delete Account
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
    </>
  );
};

export default DeleteUserModal;
