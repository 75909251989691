import { useState } from "react";
import {
  Modal,
  Typography,
  Form,
  Input,
  Button,
  Grid,
  Space,
  Alert,
} from "antd";
import { useRevalidator } from "react-router-dom";
import { IAdminUser } from "../../interface";
import { updateUserInfo } from "../../apiBridge";
import { MessageInstance } from "antd/es/message/interface";

interface Props {
  isOpen: boolean;
  controller: React.Dispatch<React.SetStateAction<boolean>>;
  deleteController: React.Dispatch<React.SetStateAction<boolean>>;
  reloadController: React.Dispatch<React.SetStateAction<boolean>>;
  currentAccount?: IAdminUser;
  notifController: MessageInstance;
}

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const AccountModal: React.FC<Props> = ({
  isOpen,
  controller,
  currentAccount,
  reloadController,
  notifController,
  deleteController
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const revalidate = useRevalidator();

  const [loadingState, setLoadingState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const triggerDeleteAccount = () => {
    controller(false);
    deleteController(true);
  }

  const onFinishEditAcc = async (values: any) => {
    if (!currentAccount) {
      return false;
    }

    setLoadingState(true);
    const { name } = values;
    const payload = {
      account_id: currentAccount.user_id,
      name
    };
    console.log(payload);
    const res = await updateUserInfo(payload);
    if (res.status === 200) {
      notifController.open({
        type: "success",
        content: "account updated",
      });

      controller(false);
      reloadController(true);
    } else {
      const data = await res.json();
      setErrorMsg(data.error || "Unknown Error. Please try again next time.");
    }

    setLoadingState(false);
  };

  return (
    <Modal
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered
      open={isOpen}
      // width={380}
      afterOpenChange={(open) => {
        if (!open) {
          form.resetFields();
          setErrorMsg("");
        }
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Title level={4}>Edit Account</Title>
        <Text type="secondary">in development.</Text>
      </div>
      {errorMsg !== "" && (
        <Alert
          type="error"
          message={errorMsg}
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}
      <Form
        name="editAccountForm"
         layout="vertical"
        onFinish={onFinishEditAcc}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
            email: currentAccount?.email,
            name: currentAccount?.name
        }}
        form={form}
        validateTrigger="onSubmit"
        requiredMark={false}
        wrapperCol={{ flex: "auto" }}
      >

        <Form.Item
          label={screens.md ? "Email" : ""}
          extra={
            <Text type="secondary" style={{ fontSize: 12 }}>
              Email is used by users to login to the IMT
            </Text>
          }
          name="email"
          rules={[{ required: true, message: "Please input a email!" }]}
        >
          <Input placeholder="email" disabled />
        </Form.Item>

        <Form.Item
          label={screens.md ? "Name" : ""}
          name="name"
          rules={[{ required: true, message: "Please input a name!" }]}
        >
          <Input placeholder="name" />
        </Form.Item>

        <div style={{ marginBottom: 20 }}>
          <Button type="primary" ghost danger onClick={triggerDeleteAccount}>
            Delete Account
          </Button>
        </div>

        <div style={{ textAlign: "right" }}>
          <Space>
            <Button onClick={() => controller(false)}>Cancel</Button>

            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="submit" loading={loadingState}>
                Save
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default AccountModal;
